import React from 'react';


export const CompanyName = () => {
    const CompanyName = 'Web Design Expressions'
    return (

        <span aria-label="Affordable Professional Web Design Solutions for Small Businesses, Affordable Web Development Agency for Custom Website Projects" title='Affordable Professional Web Design Solutions for Small Businesses, Affordable Web Development Agency for Custom Website Projects'>{CompanyName}</span>

    )
}
