import React from 'react'
import WP from '../Images/WP.svg';
import Blog from '../Images/Blog.svg';
import Support from '../Images/Support.svg';
import { CompanyName } from './CompanyName';

export default function WordPress() {
    return (
        <div>
            <div className='mt-5 lg:float-right lg:w-1/3 mx-10'><img src={WP} alt='affordable websites for small businesses' title='Harnessing WordPress: Custom Websites for Every Purpose' /></div>
            <div>
                At <CompanyName />, we have experience in the versatile WordPress in building custom websites . With its extensive range of features and functionalities, WordPress has become the go-to choice for nearly a third of the world's websites. Our web design services harness the potential of WordPress to create unique and tailored websites that perfectly suit the needs of our clients while keeping it affordable.            </div>
            <div className='pt-5'>
                Whether you're an experienced user or new to website development, Rely Design services can quickly set up a WordPress site for you. We take care of the technical aspects, allowing you to focus on your business/company goals. With WordPress, we can build professional websites for various purposes, from small business ventures to large-scale eCommerce stores, portfolios, landing pages, and more.            </div>
            <div className='pt-5'>
                The flexibility and adaptability of WordPress are unparalleled. It offers a wide array of custom themes, both free and paid, allowing us to customize the appearance and functionality of your website. We carefully select themes that align with your brand identity and create a visually appealing online presence.            </div>
            <div className='mt-5 lg:float-left lg:w-1/3 mr-10'><img src={Blog} alt='custom & affordable solutions' title='Custom & Affordable: Enhancing Your WordPress Website' /></div>
            <div className='pt-5'>
                To enhance the functionality and maintenance of your WordPress site, we leverage the power of plugins. With hundreds of custom available plugins, we can add features such as shopping carts, galleries, contact forms, and more, catering to your company's specific requirements. These plugins can be easily activated or deactivated as your needs evolve, ensuring your custom website remains dynamic, up-to-date and affordable.            </div>




            <div className='pt-5'>
                <CompanyName /> also understands the importance of custom search engine optimization (SEO) for your website's success. WordPress sites are known for their high ranking potential due to their regular updates/maintenance and SEO-friendly structure. We develop various tools and plugins to optimize your content and enhance your website's visibility in search engine results.            </div>

            <div className='pt-5'>
                In today's mobile-driven world, having a responsive website is crucial. Our team ensures that your WordPress site is fully optimized for mobile devices, providing a seamless, professional user experience across all screen sizes. By incorporating responsive design principles and making custom necessary adjustments to theme files, we make sure that your website looks stunning on any device.            </div>
            <div className='mt-5 lg:float-right lg:w-1/3 mx-10'><img src={Support} alt='functional, and affordable solutions for your website' title='Seamless Integration: WordPress Custom Blog and Support' /></div>
            <div className='pt-5'>
                WordPress also offers a built-in blog feature, which we can integrate into your website. This allows you to easily publish and manage blog posts, keeping your audience engaged with fresh content. Whether you're a blogger or want to share updates and announcements, the WordPress custom blog feature seamlessly integrates with your website.            </div>

            <div className='pt-5'>
                As a web design company, we value the support and collaboration of the WordPress community. Being an open-source platform, WordPress benefits from a global community of developers and enthusiasts. This means continuous improvements, regular updates/maintenance, and a wealth of knowledge and resources at everyone's disposal. Our expertise in WordPress enables us to provide efficient and cost-effective/affordable solutions to our clients.            </div>


            <div className='pt-5'>
                Partner with <CompanyName /> for all your web design needs, and let us leverage the power of WordPress to create a visually appealing and functional website that drives your business forward. Experience the ease of content management, the versatility of custom themes and plugins, and the ongoing support of a thriving development community.            </div>



            <div className='pt-5'>
                Contact us today to get started on your WordPress-powered website journey.
            </div>
        </div>
    )
}
